<template>
  <div class="card">
    <h1>Información Institución</h1>
  </div>
  <div class="card">
    <div class="formgrid grid nested-grid">
      <div class="col-12 md:col-8">
        <div class="grid">
          <div class="field col-12 md:col-9">
            <label for="address" class="font-bold">Nombre</label>
            <InputText class="w-full" v-model="institution.name"/>
          </div>
          <div class="field col-12 md:col-3">
            <label for="address" class="font-bold">Sigla</label>
            <InputText class="w-full" v-model="institution.acronym"/>
          </div>
          <div class="field col-12">
            <label for="address" class="font-bold">Lema</label>
            <InputText class="w-full" v-model="institution.emblem"/>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <label for="address" class="font-bold">Logo</label>
        <div class="formgrid grid">
          <div class="field col-12 md:col-5 mt-3">
            <Avatar v-if="institution.icon" :image="institution.icon" class="mr-2 w-5rem h-5rem"/>
            <Avatar v-else class="mr-2 w-7rem h-7rem"/>
          </div>
          <div class="field col-12 md:col-7 mt-3 justify-content-center align-items-center mb-3">
            <FileUpload mode="basic" @select="onFileSelect" auto custom-upload class="p-button-secondary" chooseLabel="Cargar"/>
          </div>
        </div>
      </div>
    </div>
    <div class="formgrid grid nested-grid">
      <div class="field col-12 md:col-4">
        <label for="address" class="font-bold">Resolución</label>
        <InputText class="w-full" v-model="institution.resolutionNumber"/>
      </div>
      <div class="field col-12 md:col-4">
        <label for="address" class="font-bold">Nit</label>
        <InputText class="w-full" v-model="institution.nit"/>
      </div>
      <div class="field col-12 md:col-4">
        <label for="address" class="font-bold">Código DANE</label>
        <InputText class="w-full" v-model="institution.codeDane"/>
      </div>
      <div class="field col-12 md:col-4">
        <label for="address" class="font-bold">Dirección</label>
        <InputText class="w-full" v-model="institution.address"/>
      </div>
      <div class="field col-12 md:col-4">
        <label for="address" class="font-bold">Teléfono</label>
        <InputText class="w-full" v-model="institution.phone"/>
      </div>
      <div class="field col-12 md:col-4">
        <label for="address" class="font-bold">Email</label>
        <InputText class="w-full" v-model="institution.email"/>
      </div>
      <div class="field col-12 md:col-4">
        <label for="address" class="font-bold">Pagina Web</label>
        <InputText class="w-full" v-model="institution.webpage"/>
      </div>
      <div class="field gap-4 col-12 md:col-4">
        <label for="address" class="font-bold">Departamento</label>
        <InputText class="w-full"/>
      </div>
      <div class="field gap-4 col-12 md:col-4">
        <label for="address" class="font-bold">Ciudad</label>
        <Select v-model="institution.location" :options="locations" filter optionLabel="name" placeholder="Select a Country" class="w-full md:w-56">
        </Select>
      </div>
    </div>
    <Toolbar class="mb-4 border-0">
      <template #center>
        <Button label="Guardar" icon="pi pi-save" class="p-button-success" @click="save"/>
      </template>
    </Toolbar>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import apiService from "@/service/api.service";
import { API } from "@/utils/modules";
import { getData } from "@/utils/funtions";

export default {
  data() {
    return {
      institution: {
        icon: '',
      },
      locations: [],
      toast: useToast(),
    };
  },
  methods: {
    onFileSelect(event) {
      const file = event.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.institution.icon = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    save() {
      const payload = { ...this.institution };
      if (payload.icon) {
        if (payload.icon.startsWith("data:image/png;base64,")) {
          payload.icon = payload.icon.replace("data:image/png;base64,", "");
        } else if (payload.icon.startsWith("data:image/jpeg;base64,")) {
          payload.icon = payload.icon.replace("data:image/jpeg;base64,", "");
        }
      }

      return apiService.create(API, 'institutions', payload).then(
          () => {
            this.toast.add({
              severity: 'success',
              summary: 'Completado',
              detail: 'Registros Guardados!',
              life: 3000,
            });
          },
          (error) => {
            this.toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error.response.data,
              life: 10000,
            });
          }
      );
    },
    getData() {
      this.loading = true;
      return apiService.getData(API, 'institutions').then(
          (response) => {
            this.institution = response.data[0];
            if (this.institution.icon) {
              this.institution.icon = `data:image/png;base64,${this.institution.icon}`;
            }
          },
          (error) => {
            this.toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error.response.data,
              life: 10000,
            });
          }
      );
    },
  },
  mounted() {
    getData('locations', 'locations/all', this);
    this.getData();
  },
};
</script>