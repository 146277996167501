<template>
  <div>
    <GenericCrud
        :formType="'StepperForm'"
        title="Profesores"
        path="teachers"
        property-name="firstName"
        :columns-table="columns()"
        :form-fields="steps"
        :dropdown-options="dropdownOptions()"
        :module="API()"
    />
  </div>
</template>

<script>
import {Teachers} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";
import apiService from "@/service/api.service";

export default {
  name: 'TeachersComponent',
  components: {
    GenericCrud,
  },
  methods: {
    API() {
      return API;
    },
    columns() {
      return Teachers;
    },
    dropdownOptions() {
      return {};
    },
    get(endpoint) {
      apiService.getData(API, endpoint)
          .then(response => {
            this[endpoint] = response.data;
          });
    },
  },
  data() {
    return {
      steps: [
        {
          value: 1,
          header: 'Información Personal',
          label: 'Información Personal',
          fields: [
            {
              id: 'firstName',
              label: 'Nombres',
              model: 'firstName',
              type: 'text',
              required: true,
              props: {autofocus: true}
            },
            {
              id: 'lastName',
              label: 'Apellidos',
              model: 'lastName',
              type: 'text',
              required: true,
              props: {autofocus: true}
            },
            {
              id: 'email',
              label: 'Email',
              model: 'email',
              type: 'text',
              required: true,
              props: {autofocus: true}
            },
            {
              id: 'signature',
              label: 'Firma',
              model: 'signature',
              type: 'file',
              required: false,
              props: {
                autofocus: true,
                accept: 'image/png, image/jpeg, image/jpg'
              }
            },
          ]
        },
      ]
    };
  }
};
</script>