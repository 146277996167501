<template>
  <div class="card">
    <h1> Gestión de {{ title }} </h1>
  </div>
  <div class="card">
    <Toast/>
    <Toolbar class="mb-4">
      <template v-slot:start>
        <div class="my-2">
          <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew"/>
          <Button label="Eliminar" icon="pi pi-trash" severity="danger" @click="confirmDeleteSelected"
                  :disabled="!selected || !selected.length"/>
        </div>
      </template>
      <template v-slot:end>
        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"/>
      </template>
    </Toolbar>
    <DataTable
        ref="dt"
        :value="records"
        v-model:selection="selected"
        dataKey="id"
        :paginator="true"
        sortMode="multiple"
        removableSort
        :rows="10"
        stripedRows
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
    >
      <template #header>
        <div class="flex flex-row justify-content-end">
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search mr-2"/>
                                <InputText v-model="filters['global'].value" placeholder="Search..."/>
                            </span>
        </div>
      </template>
      <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
      <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :sortable="true">
        <template #body="{ data, field }">
          <div v-if="col.type === 'percentage'">
            {{ formatNestedValue(getNestedValue(data, field)) }} %
          </div>
          <div v-else-if="col.type === 'time'">
            {{ formatTime((data[field])) }}
          </div>
          <div v-else-if="col.type === 'date'">
            {{ formatDate((data[field])) }}
          </div>
          <div v-else>
            {{ formatNestedValue(getNestedValue(data, field)) }}
          </div>
        </template>
      </Column>
      <Column headerStyle="min-width:10rem;" bodyStyle="text-align: right;">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                  @click="updateRecordSelected(slotProps.data)"/>
          <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mt-2"
                  severity="warn"
                  @click="confirmDelete(slotProps.data)"/>
        </template>
      </Column>
      <ColumnGroup v-if="totales" type="footer">
        <Row>
          <Column footer="Total:" :colspan="2" footerStyle="text-align:right" :footer-class="footerClass"/>
          <Column :footer="total" :footer-class="footerClass"/>
        </Row>
      </ColumnGroup>
    </DataTable>
    <component :is="currentFormComponent"
               :edit="edit"
               :title="title"
               :active="formDialog"
               @update:active="formDialog = $event"
               :object="record"
               @record-created="onRecordCreated"
               @record-updated="onRecordUpdated"
               :fields="formFields"
               :dropdown-options="dropdownOptions"
    />
    <Dialog v-model:visible="deleteDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
        <span v-if="record">¿Esta seguro de eliminar el registro <b>{{ record[this.propertyName] }}</b>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRecord"/>
      </template>
    </Dialog>
    <Dialog v-model:visible="deleteListDialog" :style="{ width: '450px' }" header="Confirm" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
        <span v-if="record">¿Esta Seguro de eliminar los registros seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteListDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteSelected"/>
      </template>
    </Dialog>
  </div>
</template>
<script>
import apiService from "@/service/api.service";
import {FilterMatchMode} from "@primevue/core/api";
import {markRaw} from 'vue';
import {useToast} from "primevue/usetoast";
import {getNestedValue} from "@/utils/funtions";

export default {
  props: {
    formType: {
      type: String,
      required: true
    },
    columnsTable: {
      type: Object,
      required: true
    },
    propertyName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    module: {
      type: String,
      required: true
    },
    formFields: {
      type: Array,
      required: true
    },
    dropdownOptions: {
      type: Object,
      required: false
    },
    totales: {
      type: Boolean,
      required: false
    },
    totalColumn: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      toast: useToast(),
      currentFormComponent: null,
      selectedItem: null,
      formDialog: false,
      records: [],
      selected: [],
      columns: [],
      filters: {},
      record: {},
      deleteDialog: false,
      deleteListDialog: false,
      edit: false,
    };
  },
  methods: {
    getNestedValue,
    formatTime(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // La hora '0' debe ser '12'
        return `${hours}:${formattedMinutes} ${ampm}`;
      } else {
        return null;
      }
    },
    formatDate(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
      } else {
        return null;
      }
    },
    formatNestedValue(value) {
      if (Array.isArray(value)) {
        return value.flat().filter(v => v !== undefined).join(', ');
      }
      return value;
    },
    onRecordCreated(newRecord) {
      this.record = newRecord;
      this.create();
    },
    onRecordUpdated(updated) {
      this.record = updated;
      this.update();
    },
    updateRecordSelected(edit) {
      this.record = {...edit};
      this.edit = true;
      this.formDialog = true;
    },
    exportCSV() {
      if (this.$refs.dt) {
        this.$refs.dt.exportCSV();
      } else {
        console.error('No se encontró la referencia al DataTable');
      }
    },
    confirmDelete(editCliente) {
      this.record = editCliente;
      this.deleteDialog = true;
    },
    openNew() {
      this.record = {};
      this.edit = false;
      this.submitted = false;
      this.formDialog = true;
    },
    confirmDeleteSelected() {
      this.deleteListDialog = true;
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    },
    deleteRecord() {
      this.delete()
    },
    deleteSelected() {
      this.deleteList()
    },
    deleteList() {
      return apiService.deleteList(this.module, this.path, this.selected).then(
          (response) => {
            this.records = this.records.filter((val) => !this.selected.includes(val));
            this.deleteListDialog = false;
            this.selected = null;
            this.toast.add({severity: 'success', summary: 'Completado', detail: response.data, life: 3000});
          },
          (error) => {
            this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          }
      );
    },
    delete() {
      return apiService.delete(this.module, this.path, this.record).then(
          (response) => {
            this.records = this.records.filter((val) => val.id !== this.record.id);
            this.deleteDialog = false;
            this.record = {};
            this.toast.add({severity: 'success', summary: 'Completado', detail: response.data, life: 3000});
            this.getData()
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Completado', detail: error.response.data, life: 10000});
            this.deleteDialog = false;
          }
      );
    },
    create() {
      return apiService.create(this.module, this.path, this.record).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registro Creado!', life: 3000});
            this.formDialog = false;
            this.record = {};
            this.getData();
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Successful', detail: error.response.data, life: 10000});
          }
      );
    },
    update() {
      return apiService.update(this.module, this.path, this.record).then(
          () => {
            this.toast.add({severity: 'success', summary: 'Completado', detail: 'Registro Actualizado!', life: 3000});
            this.formDialog = false;
            this.record = {};
            this.getData()
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Successful', detail: error.response.data, life: 10000});
          }
      );
    },
    getData() {
      apiService.getData(this.module, this.path)
          .then(response => {
            this.records = response.data;
          })
          .catch(error => {
            this.message = error.message || 'Error al obtener datos';
          });
    },
    async loadComponent(componentName) {
      if (componentName) {
        try {
          const component = await import(`./forms/${componentName}.vue`);
          this.currentFormComponent = markRaw(component.default);
        } catch (error) {
          console.error(`Error loading component ${componentName}:`, error);
        }
      }
    }
  },
  mounted() {
    this.getData()
    this.loadComponent(this.formType);
  },
  beforeMount() {
    this.initFilters();
    this.columns = this.columnsTable;
  },
  computed: {
    total() {
      return this.records
          .reduce((total, object) => total + object[this.totalColumn], 0);
    },
    footerClass() {
      return {
        'text-red': this.total !== 100,
        'text-green': this.total === 100
      };
    }
  },
};
</script>
