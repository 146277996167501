<template>
  <div>
    <div class="card">
      <h1>Generar Cursos</h1>
    </div>
    <div class="card">
      <DataTable
          ref="dt"
          :value="filteredGrades"
          dataKey="grade.id"
          :paginator="true"
          sortMode="multiple"
          removableSort
          :rows="10"
          stripedRows
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
          :filters="filters"
      >
        <template #header>
          <div class="flex flex-row justify-content-end">
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search mr-2"/>
              <InputText v-model="filters['global'].value" placeholder="Buscar..."/>
            </span>
          </div>
        </template>
        <Column field="grade.name" header="Nombre" sortable=""></Column>
        <Column header="Estado de Cursos" sortable="">
          <template #body="slotProps">
            <Tag :severity="slotProps.data.courseActive ? 'success' : 'danger'">
              {{ slotProps.data.courseActive ? 'Cursos Generados' : 'Sin Cursos' }}
            </Tag>
          </template>
        </Column>
        <Column header="Acciones" sortable="">
          <template #body="slotProps">
            <Button
                icon="pi pi-share-alt"
                :disabled="slotProps.data.courseActive"
                v-tooltip="slotProps.data.courseActive ? 'Cursos ya generados' : 'Generar Cursos'"
                class="p-button-rounded p-button-info"
                @click="openGenerateCoursesDialog(slotProps.data, false)"
            />
            <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning ml-2"
                v-tooltip="'Ver Cursos'"
                @click="openGenerateCoursesDialog(slotProps.data, true)"
            />
          </template>
        </Column>
      </DataTable>
    </div>

    <GenericInfoForm
        title="Generar Cursos"
        :chipData="[
        {
          icon: 'pi pi-user',
          text: `Nombre del Curso: <strong>${nameGrade}</strong>`
        },
        {
          icon: 'pi pi-users',
          text: `Estudiantes Inscritos: <strong>${totalStudents}</strong>`
        },
        {
          icon: 'pi pi-users',
          text: `Estudiantes Minimos: <strong>${minStudents}</strong>`
        }
      ]"
        :edit="false"
        :active="formDialog"
        :object="formObject"
        :fields="formFields"
        :dropdownOptions="dropdownOptions"
        @record-created="generateCourses"
        @update:active="formDialog = $event"
        :isTableMode="isTableMode"
        :tableData="tableData"
        :tableColumns="tableColumns"
    />
  </div>
</template>

<script>
import apiService from "@/service/api.service";
import {API} from "@/utils/modules";
import {getData} from "@/utils/funtions";
import {FilterMatchMode} from '@primevue/core/api';
import {useToast} from "primevue/usetoast";
import GenericInfoForm from "@/components/forms/GenericInfoForm.vue";

export default {
  name: "GenerateCoursesView",
  components: {GenericInfoForm},

  data() {
    return {
      toast: useToast(),
      gradesAdditionalInfo: [],
      dialogVisible: false,
      selectedGrade: null,
      nameGrade: '',
      formDialog: false,
      totalStudents: 0,
      minStudents: 0,
      filters: {},
      isTableMode: false,
      formObject: {
        shift: null,
        campus: null,
        suffixType: null,
        credits: 2,
        numberOfCourses: 1,
        randomize: false
      },
      formFields: [
        {
          model: 'shift',
          label: 'Jornada',
          type: 'dropdown',
          options: 'shifts',
          required: true,
          props: {optionLabel: 'name', optionValue: 'id'}
        },
        {
          model: 'campus',
          label: 'Sede',
          type: 'dropdown',
          options: 'campuses',
          required: true,
          props: {optionLabel: 'name', optionValue: 'id'}
        },
        {
          model: 'suffixType',
          label: 'Tipo de Sufijo',
          type: 'dropdown',
          options: 'suffixTypes',
          required: true,
          props: {optionLabel: 'name', optionValue: 'value'}
        },
        {model: 'numberOfCourses', label: 'Número de Cursos', type: 'number', required: true, props: {min: 1}},
        {model: 'randomize', label: 'Organizar Aleatoriamente', type: 'checkbox', required: false}
      ],
      dropdownOptions: {
        shifts: [],
        campuses: [],
        suffixTypes: [
          {name: 'Numérico', value: 'numeric'},
          {name: 'Alfabético', value: 'alphabetic'}
        ]
      },
      tableData: [],
      tableColumns: [
        {field: 'name', header: 'Nombre del Curso'},
        {field: 'shift.name', header: 'Jornada'},
        {field: 'campus.name', header: 'Sede'},
        {field: 'credits', header: 'Créditos'},
      ]
    };
  },
  computed: {
    filteredGrades() {
      const filterValue = this.filters['global'].value?.toLowerCase();
      return this.gradesAdditionalInfo.filter(grade => grade.grade.name.toLowerCase().includes(filterValue));
    }
  },
  methods: {
    openGenerateCoursesDialog(gradeInfo, isTableMode) {
      const grade = gradeInfo.grade;
      this.selectedGrade = grade;
      this.nameGrade = grade.name;
      this.minStudents = grade.minStudents;
      this.totalStudents = gradeInfo.numStudents;
      this.formObject.numberOfCourses = Math.max(1, Math.ceil(this.totalStudents / this.minStudents));
      this.isTableMode = isTableMode;

      if (isTableMode) {
        this.tableData = [];
        if (gradeInfo.courseActive) {
          this.loadTableData(grade.id);
        }
      }

      this.formDialog = true;
    },
    loadTableData(gradeId) {
      apiService.getData(API, `courses/${gradeId}`)
          .then(response => {
            this.tableData = response.data;
          })
          .catch(() => {
            this.toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al cargar los datos de la tabla.',
              life: 3000
            });
          });
    },
    generateCourses(formData) {
      const courseRequest = {
        prefix: this.selectedGrade.prefix,
        numCourses: formData.numberOfCourses,
        studentAssignment: formData.randomize,
        course: {
          name: '',
          credits: formData.credits,
          shift: {id: formData.shift},
          campus: {id: formData.campus},
          grade: {id: this.selectedGrade.id},
          suffixType: formData.suffixType
        }
      };

      console.log("Course Request:", courseRequest);
      apiService.create(API, 'grades/generate', courseRequest)
          .then(() => {
            getData('courses', 'courses', this);
            this.toast.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'Cursos generados exitosamente.',
              life: 3000
            });
          })
          .catch(() => {
            this.toast.add({severity: 'error', summary: 'Error', detail: 'Error al generar los cursos.', life: 3000});
          });
    },
    initFilters() {
      this.filters = {
        global: {value: '', matchMode: FilterMatchMode.CONTAINS}
      };
    },
    loadAllData() {
      const shiftsPromise = apiService.getData(API, 'shifts');
      const campusesPromise = apiService.getData(API, 'campuses');
      const gradesAdditionalInfoPromise = apiService.getData(API, 'grades/additional-info');
      Promise.all([shiftsPromise, campusesPromise, gradesAdditionalInfoPromise])
          .then(([shiftsResponse, campusesResponse, gradesAdditionalInfoResponse]) => {
            this.gradesAdditionalInfo = gradesAdditionalInfoResponse.data;
            this.dropdownOptions.shifts = shiftsResponse.data;
            this.dropdownOptions.campuses = campusesResponse.data;
            console.log("Grades Additional Info:", gradesAdditionalInfoResponse.data);
          })
          .catch(() => {
            this.toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al cargar los datos de dropdowns.',
              life: 3000
            });
          });
    }
  },
  mounted() {
    this.loadAllData();
  },
  beforeMount() {
    this.initFilters();
  }
};
</script>
