class Columna {
    constructor(field, header, type) {
        this.field = field;
        this.header = header;
        this.type = type;
    }
}

const Users = [
    new Columna('username', 'Nombre'),
    new Columna('email', 'Email'),
    new Columna('rol', 'Rol'),
];

const Guardians = [
    new Columna('firstName', 'Nombres'),
    new Columna('lastName', 'Apellidos'),
    new Columna('relationship', 'Parentesco'),
];

const Basic = [
    new Columna('name', 'Nombre'),
];
const Shifts = [
    new Columna('name', 'Nombre'),
    new Columna('startTime', 'Hora Inicio', 'time'),
    new Columna('finalTime', 'Hora fin','time'),
];

const Subjects = [
    new Columna('name', 'Nombre'),
    new Columna('hours', 'Intensidad Horaria'),
];

const Performances = [
    new Columna('name', 'Nombre'),
    new Columna('initialRange', 'Rango Inicial'),
    new Columna('finalRange', 'Rango Final'),
];

const Years = [
    new Columna('name', 'Nombre'),
    new Columna('state', 'Estado'),
];

const Periods = [
    new Columna('name', 'Nombre'),
    new Columna('percentage', 'Porcentaje', 'percentage'),
    new Columna('state', 'Estado'),
];
const Grades = [
    new Columna('name', 'Nombre'),
    new Columna('academicLevel.name', 'Nivel'),
    new Columna('minStudents', 'Número Mínimo de Estudiantes'),
    new Columna('prefix', 'Prefijo para Cursos'),
]

const Components = [
    new Columna('name', 'Nombre'),
    new Columna('weight', 'Porcentaje', 'percentage'),
];

const Concepts = [
    new Columna('code', 'Código'),
    new Columna('description', 'Descripción'),
    new Columna('period.name', 'Periodo Académico'),
];


const Courses = [
    new Columna('name', 'Nombre'),
    new Columna('teacher.firstName', 'Director de Curso'),
    new Columna('shift.name', 'Jornada'),
    new Columna('campus.name', 'Sede'),
    new Columna('grade.name', 'Grado'),
];

const StudentCourses = [
    new Columna('student.firstName', 'Alumno'),
    new Columna('course.name', 'Curso'),
];

const Students = [
    new Columna('name', 'Nombres'),
    new Columna('firstName', 'Nombres'),
    new Columna('lastName', 'Apellidos')
];

const Teachers = [
    new Columna('firstName', 'Nombres'),
    new Columna('lastName', 'Apellidos'),
    new Columna('email', 'Email')
];



export {Guardians}
export {Students}
export {Subjects}
export {Basic}
export {Years}
export {Concepts}
export {Courses}
export {Components}
export {Performances}
export {Teachers}
export {StudentCourses}
export {Periods}
export {Grades}
export {Users}
export {Shifts};

