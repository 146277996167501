<template>
  <div class="card text-center">
    <Toolbar class="border-0">
      <template v-slot:start>
        <h1> Gestión de Cursos </h1>
      </template>
      <template v-slot:end>
        <DynamicChip v-for="(chip, index) in chips" :key="index" :icon="chip.icon" :text="chip.text" :data="chip.data"></DynamicChip>

      </template>
    </Toolbar>
  </div>
  <div>
    <CoursesComponent
        :chipData="chipData"
        :formType="'GenericForm'"
        title="Cursos"
        path="courses"
        path-options="search"
        property-name="name"
        :columns-table="columns()"
        :columns-table-options="columnsOptional()"
        :form-fields="fields"
        :module="API()"
        :dropdown-options="dropdownOptions()"
        :show-optional-button="true"
        @course-selected="updateSelectedCourse"
    />
  </div>
</template>

<script>
import {Courses} from "@/entities/entities";
import CoursesComponent from "@/components/CoursesComponent.vue";
import {API} from "@/utils/modules";
import apiService from "@/service/api.service";
import DynamicChip from "@/components/cardChips/DynamicChip.vue";
export default {
  components: {
    DynamicChip,
    CoursesComponent,
  },
  data() {
    return {
      year: '',
      chips:[
      ],

      props: {
        selectedCourse: Object
      },
      nameGrade: '',
      totalStudents: 0,

      shifts: [],
      campuses: [],
      grades: [],
      teachers: [],
      selectedCourse: null,
      fields: [
        {
          id: 'teacher',
          label: 'Director de Curso',
          options: 'teachers',
          model: 'teacher',
          type: 'dropdown',
          props: {
            placeholder: 'Seleccione un director de curso',
            showClear: true,
            filter: true,
            optionLabel: 'firstName'
          }
        },
        {
          id: 'grade',
          label: 'Grado',
          options: 'grades',
          model: 'grade',
          type: 'dropdown',
          required: true,
          props: {
            placeholder: 'Seleccione un grado',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        },
        {
          id: 'shift',
          label: 'Jornada',
          options: 'shifts',
          model: 'shift',
          type: 'dropdown',
          required: true,
          props: {
            placeholder: 'Seleccione una jornada',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        },
        {
          id: 'name',
          label: 'Nombre',
          model: 'name',
          type: 'text',
          required: true,
          props: {autofocus: true}
        },
        {
          id: 'campus',
          label: 'Sede',
          options: 'campuses',
          model: 'campus',
          type: 'dropdown',
          required: true,
          props: {
            placeholder: 'Seleccione una sede',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        }
      ]

    };
  },
  computed: {
    chipData() {
      return [
        {
          id: 'course',
          icon: 'pi pi-user',
          text: `Nombre del Curso: <strong>${this.selectedCourse ? this.selectedCourse.name : ''}</strong>`,
          data: {
            courseName: this.selectedCourse ? this.selectedCourse.name : ''
          }
        },
        {
          icon: 'pi pi-users',
          text: `Director de Curso: <strong>${this.selectedCourse && this.selectedCourse.teacher ? (this.selectedCourse.teacher.firstName && this.selectedCourse.teacher.lastName ? this.selectedCourse.teacher.firstName + ' ' + this.selectedCourse.teacher.lastName : 'No tiene director registrado') : 'No tiene director registrado'}</strong>`,
          data: {
            totalStudents: this.selectedCourse ? this.selectedCourse.totalStudents : 0
          }
        }
      ];
    }
  },
  methods: {
    API() {
      return API;
    },
    columns() {
      return Courses;
    },
    columnsOptional() {
      return [
        {field: 'student.firstName', header: 'Nombre'},
        {field: 'student.lastName', header: 'Apellido'},
        {field: 'student.IDENTIFICATION', header: 'ID Estudiante'}
      ];
    },
    get(endpoint) {
      apiService.getData(API, endpoint)
          .then(response => {
            this[endpoint] = response.data;
          });
    },
    dropdownOptions() {
      return {
        teachers: this.teachers,
        shifts: this.shifts,
        campuses: this.campuses,
        grades: this.grades,
      };
    },
    updateSelectedCourse(course) {

      this.nameGrade = course.name;
      this.selectedCourse = course;
    },
    yearActive() {
      apiService.getData(API, 'years/active').then(response => {
        this.chips = [
          {
            text: 'Año Activo: <strong>' + response.data.name + '</strong>',
            icon: 'pi pi-calendar'
          },
        ];
      }).catch(error => {
        console.error("Error al obtener el año activo", error);
      });
    }
  },
  mounted() {
    this.year = this.yearActive();

    this.get('shifts');
    this.get('grades');
    this.get('campuses');
    this.get('teachers');
  }
};
</script>
