<template>
  <div class="card">
    <h2>Informes Académicos</h2>
  </div>
  <div class="card">
    <Toolbar class="border-0">
      <template #start>
        <FloatLabel variant="in">
          <Dropdown
              id="course"
              v-model="selectedCourse"
              :options="courses"
              filter
              optionLabel="name"
              class="w-full md:w-14rem"
              @change="loadStudents"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ slotProps.value.name }}</div>
              </div>
            </template>
            <template #option="slotProps">
              <div>{{ slotProps.option.name }}</div>
            </template>
          </Dropdown>
          <label for="course">Seleccione un curso</label>
        </FloatLabel>

      </template>
      <template #end>
        <Button
            label="Generar Informe"
            icon="pi pi-file"
            class="p-button-rounded p-button-info"
            @click="print(this.selectedCourse)"
            :disabled="!selectedCourse || students.length === 0"
            v-tooltip.top="!selectedCourse ? 'Seleccione un curso' : students.length === 0 ? 'No hay estudiantes en este curso' : 'Generar Informe'"
        />
      </template>
    </Toolbar>
  </div>

  <div class="card">
    <div v-if="selectedCourse">
      <template v-if="loading">
        <div class="text-center">
          <ProgressSpinner
              style="width: 50px; height: 50px"
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
              aria-label="Custom ProgressSpinner"
          />
        </div>
      </template>
      <template v-else>
        <div v-if="students.length > 0">
          <DataTable :value="students" :paginator="true" :rows="10">
            <Column field="fullName" header="Nombre del Estudiante"></Column>
            <Column field="document" header="Documento"></Column>
            <Column header="Acciones">
              <template #body="slotProps">
                <Button
                    icon="pi pi-file"
                    class="p-button-rounded p-button-info"
                    @click="print(slotProps.data)"
                    v-tooltip.top="'Generar Informe'"
                />
              </template>
            </Column>
          </DataTable>
        </div>
        <div v-else>
          <p>No hay estudiantes registrados en este curso.</p>
        </div>
      </template>
    </div>
  </div>

  <Dialog :visible="loadingDialog" modal :style="{width: '50vw'}">
    <div class="flex justify-content-center">
      <ProgressSpinner
          style="width: 50px; height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
      />
    </div>
    <p class="text-center">Generando informe, por favor espere...</p>
  </Dialog>
</template>

<script>
import { API } from "@/utils/modules";
import apiService from "@/service/api.service";
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import { useToast } from "primevue/usetoast";

export default {
  components: {
    Dropdown,
    DataTable,
    Column,
    Button,
    ProgressSpinner,
    Dialog
  },
  directives: {
    tooltip: Tooltip
  },
  data() {
    return {
      toast: useToast(),
      selectedCourse: null,
      courses: [],
      students: [],
      loading: false,
      loadingDialog: false
    };
  },
  methods: {
    getCourses() {
      apiService.getData(API, 'courses')
          .then(response => {
            this.courses = response.data;
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error al cargar los datos de los cursos.',
              life: 3000,
            });
          });
    },
    loadStudents() {
      this.loading = true;
      this.students = [];
      if (this.selectedCourse) {
        apiService.getData(API, `student-courses/course/${this.selectedCourse.id}`)
            .then(response => {
              this.students = response.data.map(item => ({
                id: item.student.id,
                fullName: `${item.student.firstName} ${item.student.lastName}`,
                document: item.student.identification || 'No disponible',
                studentData: item.student
              }));
            })
            .catch(() => {
              this.$toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error al cargar los estudiantes del curso.',
                life: 3000,
              });
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.loading = false;
      }
    },
    print(student) {
      this.loadingDialog = true;
      const fileName = student.name == null
          ? `INFORME ACADEMICO ${this.selectedCourse.name} - ${student.fullName}.pdf`
          : `INFORME ACADEMICO ${this.selectedCourse.name}.pdf`;

      apiService.print(API, 'reports-academics/generate', { id: student ? student.id : this.selectedCourse.id })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();

            this.toast.add({
              severity: 'success',
              summary: 'Éxito',
              detail: `Archivo descargado: ${fileName}`,
              life: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error.response.data,
              life: 3000,
            });
          })
          .finally(() => {
            this.loadingDialog = false;
          });
    },
  },
  mounted() {
    this.getCourses();
  },
}
</script>
