<template>
  <div class="grid">
    <div v-for="card in cards" :key="card.title" class="col-12 md:col-3">
      <CardInfo
          :title="card.title"
          :value="card.value"
          :iconClass="card.iconClass"
          :iconBgClass="card.iconBgClass"
          subtitle=""
      />
    </div>
  </div>
  <div class="card">
    <Toolbar class="border-0">
      <template v-slot:start>
        <FloatLabel variant="in">
          <Select
              v-model="selectedYear"
              :options="years"
              optionLabel="name"
              @change="onYearChange"
              id="year"
              class="w-25rem"
              filter
              filterPlaceholder="Buscar año">

            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ slotProps.value.name }}</div>
              </div>
            </template>
            <template #option="slotProps">
              <div>{{ slotProps.option.name }}</div>
            </template>
          </Select>
          <label for="year">Seleccionar Año</label>
        </FloatLabel>
      </template>
      <template v-slot:end>
        <FloatLabel variant="in">
          <Select
              v-model="selectedLevelAcademic"
              :options="levelAcademics"
              optionLabel="name"
              @change="onYearChange"
              id="level"
              class="w-25rem"
              filter
              filterPlaceholder="Buscar nivel académico">

            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ slotProps.value.name }}</div>
              </div>
            </template>
            <template #option="slotProps">
              <div>{{ slotProps.option.name }}</div>
            </template>
          </Select>
          <label for="level">Seleccionar Un Nivel Académico</label>
        </FloatLabel>
      </template>
    </Toolbar>
  </div>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Promedio por academico de los cursos</h5>
        <ProgressSpinner v-if="loading.averageScorePeriods" style="margin: auto;"/>
        <div v-else>
          <Chart
              v-if="averageScorePeriodsData.labels.length"
              type="line"
              :data="averageScorePeriodsData"
              :options="averageScorePeriodsOptions"
              class="w-full"
          />
          <p v-else class="text-center">No hay información disponible</p>
        </div>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12 md:col-6">
      <div class="card">
        <h5>Fallas por curso</h5>
        <ProgressSpinner v-if="loading.totalAbsencesCourse" style="margin: auto;"/>
        <div v-else>
          <Chart
              v-if="totalAbsencesCourseData.labels.length"
              type="bar"
              :data="totalAbsencesCourseData"
              :options="totalAbsencesCourseOptions"
              class="w-full"
          />
          <p v-else class="text-center">No hay información disponible</p>
        </div>
      </div>

      <div class="card">
        <h5>Promedio por periodo por Asignatura</h5>
        <ProgressSpinner v-if="loading.averageScorePeriodsSubject" style="margin: auto;"/>
        <div v-else>
          <Chart
              v-if="averageScorePeriodsSubjectData.labels.length"
              type="radar"
              :data="averageScorePeriodsSubjectData"
              :options="averageScorePeriodsSubjectOption"
              class="w-full"
          />
          <p v-else class="text-center">No hay información disponible</p>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="card">
        <h5>Primer Puesto de los cursos</h5>
        <ProgressSpinner v-if="loading.studentRanking" style="margin: auto;"/>
        <div v-else>
          <DataTable
              :value="studentRanking"
              dataKey="number"
              class="w-full"
              :rows="10"
              :rowsPerPageOptions="[5, 10, 25]"
              paginator
              v-model:filters="filters"
          >
            <template #header>
              <div class="flex flex-row justify-content-end">
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search mr-2"/>
                                <InputText v-model="filters['global'].value" placeholder="Search..."/>
                            </span>
              </div>
            </template>
            <Column field="course" header="Curso" style="text-align: left" :sortable="true"/>
            <Column field="student" header="Estudiante" style="text-align: left" :sortable="true"/>
            <Column field="average" header="Nota Final" style="text-align: center" :sortable="true"/>
          </DataTable>
          <p v-if="!studentRanking.length" class="text-center">No hay información disponible</p>
        </div>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Promedio de notas por curso</h5>
        <ProgressSpinner v-if="loading.averageScoreSubjects" style="margin: auto;"/>
        <div v-else>
          <Chart
              v-if="averageScoreSubjectsData.labels.length"
              type="bar"
              :data="averageScoreSubjectsData"
              :options="averageScoreSubjectsOptions"
              class="w-full"
          />
          <p v-else class="text-center">No hay información disponible</p>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import apiService from "@/service/api.service";
import {API} from "@/utils/modules";
import CardInfo from "@/views/dashboard/CardInfo.vue";
import Select from "primevue/select";
import ProgressSpinner from "primevue/progressspinner";
import {FilterMatchMode} from "@primevue/core/api";

export default {
  name: "SchoolDashboard",
  components: {CardInfo, Select, ProgressSpinner},
  data() {
    return {
      selectedYear: {},
      selectedLevelAcademic: {},
      filters: '',
      levelAcademics: [],
      years: [],
      loading: {
        averageScorePeriods: true,
        averageScoreSubjects: true,
        totalAbsencesCourse: true,
        averageScorePeriodsSubject: true,
        studentRanking: true,
      },
      averageScorePeriodsOptions: {
        tension: 0.3,
      },
      averageScoreSubjectsOptions: {},
      totalAbsencesCourseOptions: {},
      averageScorePeriodsSubjectOption: {},
      totalStudents: 0,
      totalTeachers: 0,
      totalCourses: 0,
      totalSubjects: 0,
      cards: [],
      averageScorePeriodsData: {labels: [], datasets: []},
      averageScoreSubjectsData: {labels: [], datasets: []},
      totalAbsencesCourseData: {labels: [], datasets: []},
      averageScorePeriodsSubjectData: {labels: [], datasets: []},
      studentRanking: [],
    };
  },
  methods: {
    getData(endpoint) {
      return apiService.getData(API, endpoint)
          .then(response => response?.data || {labels: [], data: []})
          .catch(error => {
            console.error(`Error fetching ${endpoint}:`, error);
            return {labels: [], data: []};
          });
    },
    getTotals() {
      return Promise.all([
        this.getData('students').then(data => {
          this.totalStudents = data.length;
        }),
        this.getData('teachers').then(data => {
          this.totalTeachers = data.length;
        }),
        this.getData('courses').then(data => {
          this.totalCourses = data.length;
        }),
        this.getData('subjects').then(data => {
          this.totalSubjects = data.length;
        }),
      ]).then(() => this.updateCards());
    },
    updateCards() {
      this.cards = [
        {
          title: "Total de Estudiantes",
          value: this.totalStudents,
          iconClass: "pi pi-users",
          iconBgClass: "bg-blue-100"
        },
        {title: "Total de Profesores", value: this.totalTeachers, iconClass: "pi pi-user", iconBgClass: "bg-green-100"},
        {title: "Total de Cursos", value: this.totalCourses, iconClass: "pi pi-book", iconBgClass: "bg-orange-100"},
        {
          title: "Total de Asignaturas",
          value: this.totalSubjects,
          iconClass: "pi pi-briefcase",
          iconBgClass: "bg-purple-100"
        }
      ];
    },
    setupChartData(endpoint, chartData, loadingKey) {
      this.loading[loadingKey] = true;
      return this.getData(`dataset/${endpoint}?year=${this.selectedYear.name}&levelAcademic=${this.selectedLevelAcademic.name}`)
          .then(response => {
            chartData.labels = response.labels || [];
            chartData.datasets = response.data || [];
          })
          .catch(error => {
            chartData.labels = [];
            chartData.datasets = [];
            this.toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error.response?.data || 'Error desconocido',
              life: 10000
            });
          })
          .finally(() => {
            this.loading[loadingKey] = false;
          });
    },
    getStudentRanking() {
      this.loading.studentRanking = true;
      return this.getData(`dataset/top-ten-final-scores?year=${this.selectedYear.name}&levelAcademic=${this.selectedLevelAcademic.name}`)
          .then(data => {
            console.log(data);
            this.studentRanking = data;
          })
          .finally(() => this.loading.studentRanking = false);
    },
    onYearChange() {
      this.getTotals();
      this.setupChartData("average-score-period", this.averageScorePeriodsData, "averageScorePeriods");
      this.setupChartData("average-score-subject", this.averageScoreSubjectsData, "averageScoreSubjects");
      this.setupChartData("total-absences-course", this.totalAbsencesCourseData, "totalAbsencesCourse");
      this.setupChartData("score_period_average_subject", this.averageScorePeriodsSubjectData, "averageScorePeriodsSubject");
      this.getStudentRanking();
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    }
  },
  mounted() {
    this.getData('years').then(data => {
      this.years = data;
      const activeYear = this.years.find(year => year.state === 'A');
      this.selectedYear = activeYear ? activeYear : this.years[0];

    });
    this.getData('grades').then(data => {
      this.levelAcademics = [{name: "Todos"}, ...data];
      this.selectedLevelAcademic = this.levelAcademics[0];
      this.onYearChange();
    });

  },
  created() {
    this.initFilters();
  }
};
</script>

