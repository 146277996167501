<template>
  <Dialog v-model:visible="formDialog" :style="{ width: '80%' }" :header="title" :modal="true"
          class="p-fluid" @hide="onHide">
    <div class="card">
      <Toolbar class="border-0">
        <template v-slot:center>
          <Chip v-for="(chip, index) in chipData" :key="index" class="h-2.5rem mr-3">
            <i :class="chip.icon" style="font-size: 2rem"></i>
            <span class="ml-2 font-medium" v-html="chip.text"></span>
          </Chip>
        </template>
      </Toolbar>
    </div>

    <template v-if="!isTableMode">
      <div v-for="(field, index) in fields" :key="index" class="field">
        <label :for="field.model">{{ field.label }}</label>
        <component
            :is="getFieldComponent(field.type)"
            :id="field.model"
            v-model="record[field.model]"
            :required="field.required"
            :options="field.type === 'dropdown' ? dropdownOptions[field.options] : undefined"
            :optionLabel="field.props?.optionLabel"
            :optionValue="field.props?.optionValue"
            :class="{ 'p-invalid': submitted && field.required && !record[field.model], 'w-full': true }"
            :minFractionDigits="field.minFractionDigits"
            :timeOnly="field.timeOnly"
            hourFormat="12"
            :binary="field.type === 'checkbox'"
            v-bind="field.props"
        />
        <small class="p-invalid" v-if="submitted && field.required && !record[field.model]">
          {{ field.label }} es requerido.
        </small>
      </div>
    </template>

    <template v-else>
      <DataTable :value="tableData" :paginator="true" :rows="10" dataKey="id" :rowsPerPageOptions="[5, 10, 20]"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros">
        <Column v-for="col in tableColumns" :key="col.field" :field="col.field" :header="col.header" :sortable="col.sortable"></Column>
      </DataTable>
    </template>

    <template #footer>
      <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
      <Button v-if="!edit && !isTableMode" label="Confirmar" icon="pi pi-check" class="p-button-text" @click="createRecord"/>
      <Button v-if="edit && !isTableMode" label="Editar" icon="pi pi-check" class="p-button-text" @click="updateRecord"/>
    </template>
  </Dialog>
</template>

<script>
import { useToast } from "primevue/usetoast";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

export default {
  props: {
    chipData: {
      type: Array,
      required: true,
      default: () => []
    },
    edit: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    object: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: false,
      default: () => []
    },
    dropdownOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    tableData: {
      type: Array,
      required: false,
      default: () => []
    },
    tableColumns: {
      type: Array,
      required: false,
      default: () => []
    },
    isTableMode: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      toast: useToast(),
      record: { ...this.object },
      submitted: false,
      formDialog: false
    }
  },
  watch: {
    active(newValue) {
      this.formDialog = newValue;
    }
  },
  methods: {
    onHide() {
      this.submitted = false;
      this.$emit('update:active', false);
    },
    hideDialog() {
      this.formDialog = false;
      this.submitted = false;
      this.$emit('update:active', false);
    },
    createRecord() {
      if (this.validateForm())
        this.$emit('record-created', this.record);
    },
    updateRecord() {
      if (this.validateForm())
        this.$emit('record-updated', this.record);
    },
    validateForm() {
      this.submitted = true;
      return this.fields.every(field => !field.required || this.record[field.model]);
    },
    getFieldComponent(type) {
      switch (type) {
        case 'text':
          return InputText;
        case 'dropdown':
          return Dropdown;
        case 'number':
          return InputNumber;
        case 'time':
          return Calendar;
        case 'checkbox':
          return Checkbox;
        default:
          return InputText;
      }
    },
    convert(obj) {
      const transformedObject = { ...obj };
      this.fields.forEach(field => {
        const { model, type } = field;
        if (type === "time" && typeof transformedObject[model] === "number") {
          transformedObject[model] = new Date(transformedObject[model]);
        }
        if (type === "number") {
          transformedObject[model] = Number(transformedObject[model]);
        }
      });

      return transformedObject;
    }
  },
  beforeUpdate() {
    if (!this.isTableMode) {
      const transformedObject = this.convert(this.object);
      this.record = { ...transformedObject };
    }
  },
  components: {
    DataTable,
    Column,
    Button
  }
}
</script>