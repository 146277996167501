<template>
  <div class="card">
    <h1> Evaluar Estudiantes </h1>
  </div>
  <div class="card" v-if="!evaluating">
    <DataTable
        ref="dt"
        :value="assignments"
        v-model:selection="selected"
        dataKey="id"
        :paginator="true"
        sortMode="multiple"
        removableSort
        :rows="10"
        stripedRows
        v-model:filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
    >
      <template #header>
        <div class="flex flex-row justify-content-end">
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search mr-2"/>
                                <InputText v-model="filters['global'].value" placeholder="Search..."/>
                            </span>
        </div>
      </template>
      <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :sortable="true">
        <template #body="{ data, field }">
          <div>
            {{ formatNestedValue(getNestedValue(data, field)) }}
          </div>
        </template>
      </Column>
      <Column headerStyle="min-width:10rem;" bodyStyle="text-align: right;">
        <template #body="slotProps">
          <Button icon="pi pi-eye" v-tooltip="'Ver Historia'" class="p-button-rounded p-button-info mr-2"
                  @click="view(slotProps.data)"/>
          <Button icon="pi pi-check-square" v-tooltip="'Evaluar'" class="p-button-rounded p-button-success mr-2"
                  @click="evaluate(slotProps.data)"/>
          <Button icon="pi pi-print" v-tooltip="'Descargar Planilla Evaluación'" severity="warn" class="p-button-rounded mr-2"
                  @click="print(slotProps.data)"/>
        </template>
      </Column>
    </DataTable>
  </div>
  <EvaluateVIew v-else :assignment="assignment" :period="period" :active="evaluating"
                @update:active="evaluating = $event"></EvaluateVIew>
  <Dialog v-model:visible="finalScoreDialog" :style="{ width: '90%' }" header="Visualizar historia académica"
          :modal="true"
          class="p-fluid">
    <ScoresByAssignmentComponent :assignment="assignment"/>
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
    </template>
  </Dialog>
</template>
<script>

import {API} from "@/utils/modules";
import {getData, getNestedValue, getUser} from "@/utils/funtions";
import {FilterMatchMode} from "@primevue/core/api";
import EvaluateVIew from "@/components/EvaluateComponent.vue";
import {useToast} from "primevue/usetoast";
import apiService from "@/service/api.service";
import ScoresByAssignmentComponent from "@/components/ScoresByAssignmentComponent.vue";

export default {
  components: {ScoresByAssignmentComponent, EvaluateVIew},
  data() {
    return {
      finalScoreDialog: false,
      toast: useToast(),
      evaluating: false,
      assignments: [],
      assignment: {},
      course: {},
      period: {},
      selected: [],
      columns: [{field: 'subject.name', header: 'Asignatura'}, {field: 'course.name', header: 'Curso'}],
      filters: {},
      finalScoreStudent: {},
      finalScores: [],
    };
  },
  methods: {
    getNestedValue,
    view(data) {
      this.assignment = data;
      this.getData();
    },
    hideDialog() {
      this.finalScoreDialog = false;
    },
    getData() {
      this.finalScoreStudent.assignment = this.assignment;
      return apiService.getDataPost(API, 'final-scores/assignment', this.finalScoreStudent).then(
          (response) => {
            this.finalScores = response.data
            this.finalScoreDialog = true
          },
          (error) => {
            this.toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    print(data) {
      this.assignment = data
      apiService.print(API,'reports/generate',this.assignment).then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Planilla Evaluación '+this.assignment.course.name+'.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
          },
      )
    },
    evaluate(data) {
      this.assignment = data;
      this.evaluating = true
    },
    formatNestedValue(value) {
      if (Array.isArray(value)) {
        return value.flat().filter(v => v !== undefined).join(', ');
      }
      return value;
    },
    initFilters() {
      this.filters = {
        global: {value: null, matchMode: FilterMatchMode.CONTAINS}
      };
    }
  },
  mounted() {
    getData('assignments', 'assignments/email?email=' + getUser(), this);
    getData('period', 'periods/active', this)
  },
  created() {
    this.initFilters();
  }
};
</script>


