<template>
  <div class="card shadow-lg p-3 border-round hover:shadow-xl transition-shadow">
    <div class="flex justify-between mb-2">
      <Toolbar class="border-0 w-full h-1/2">
        <template v-slot:start>
          <div>
            <span class="block text-600 font-semibold mb-1">{{ title }}</span>
            <div class="text-900 font-bold text-2xl">{{ value }}</div>
          </div>
        </template>
        <template v-slot:end>
          <div :class="['flex align-items-center justify-content-center bg-cyan-100 border-round p-2 ml-2', iconBgClass]">
            <i :class="['text-2xl', iconClass]"></i>
          </div>
        </template>
      </Toolbar>

    </div>
    <span :class="valueChangeClass" class="flex align-items-center">
      <i :class="valueChange.startsWith('+') ? 'pi pi-arrow-up text-green-500' : 'pi pi-arrow-down text-red-500'" v-if="valueChange"></i>
      <span class="ml-2">{{ valueChange }}</span>
    </span>
    <span class="text-500 mt-1">{{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  name: 'CardInfo',
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    },
    iconClass: {
      type: String,
      required: true
    },
    iconBgClass: {
      type: String,
      required: true
    },
    valueChange: {
      type: String,
      default: ''
    },
    valueChangeClass: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      required: false

    }
  }
};
</script>

<style scoped>
.card {
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}
</style>
