<template>
  <div class="card">
    <h1>Asignación Académica</h1>
  </div>
  <div class="card">
    <DataTable
        ref="dt"
        :value="teachers"
        v-model:selection="selected"
        dataKey="id"
        :paginator="true"
        sortMode="multiple"
        removableSort
        :rows="10"
        stripedRows
        v-model:filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros">
      <template #header>
        <div class="flex flex-row justify-content-end">
                                <span class="block mt-2 md:mt-0 p-input-icon-left">
                                    <i class="pi pi-search mr-2"/>
                                    <InputText v-model="filters['global'].value" placeholder="Search..."/>
                                </span>
        </div>
      </template>
      <Column field="fullName" header="Docente"/>
      <Column field="assignedHours" header="Intensidad Horaria" :sortable="true">
        <template #body="{data}">
          <div>
            <MeterGroup :value="Array.isArray(data.assignedMeter) ? data.assignedMeter : [{value:0}]" :max="target">
              <template #meter="slotProps">
                <span v-if="slotProps.value.value<target" :class="slotProps.class"
                      :style="{ background: `#fbbf24`, width: slotProps.size }"/>
                <span v-if="slotProps.value.value>target" :class="slotProps.class"
                      :style="{ background: `linear-gradient(to right, #fbbf24, #34d399)`, width: slotProps.size }"/>
              </template>
              <template #label="{ value }">
                <template v-for="(val,index) of value" :key="index">
                  <p>{{ val.value }}/{{ target }} Horas</p>
                </template>
              </template>
            </MeterGroup>
          </div>
        </template>
      </Column>
      <Column headerStyle="min-width:10rem;" bodyStyle="text-align: right;">
        <template #body="slotProps">
          <Button icon="pi pi-eye" v-tooltip="'Ver Asignación'" class="p-button-rounded p-button-info mr-2"
                  @click="view(slotProps.data)"/>
          <Button icon="pi pi-briefcase" v-tooltip="'Asignar'" class="p-button-rounded p-button-success mt-2"
                  @click="assign(slotProps.data)"/>
        </template>
      </Column>
    </DataTable>
  </div>

  <Dialog v-model:visible="assignmentViewDialog" :style="{ width: '90%' }" header="Visualizar asignación académica"
          :modal="true"
          class="p-fluid">
    <div class="card">
      <Toolbar class="border-0">
        <template v-slot:start>
          <Chip class="h-2.5rem mr-3">
            <i class="pi pi-user" style="font-size: 2rem"></i>
            <span class="ml-2 font-medium">Docente: <strong>{{ teacher.fullName }}</strong></span>
          </Chip>
          <Chip class="h-2.5rem mr-3">
            <i class="pi pi-clock" style="font-size: 2rem"></i>
            <span class="ml-2 font-medium">Carga Académica: <strong>{{ assignedHours }}</strong> horas</span>
          </Chip>
          <Chip class="h-2.5rem mr-3">
            <i class="pi pi-book" style="font-size: 2rem"></i>
            <span class="ml-2 font-medium">Total Asignaturas: <strong>{{ totalSubjects }}</strong></span>
          </Chip>
        </template>
      </Toolbar>
    </div>
    <DataTable v-if="assignments && assignments.length > 0"
               :value="assignments"
               :paginator="true"
               sortMode="multiple"
               removableSort
               :rows="10"
               stripedRows
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5, 10, 25]"
               currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
    >
      <Column header="Asignatura" field="subject.name" :sortable="true"/>
      <Column header="Curso" field="course.name" :sortable="true"/>
      <Column header="Horas" field="hours" :sortable="true"/>
    </DataTable>
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
    </template>
  </Dialog>

  <Dialog v-model:visible="assignmentDialog" @hide="hideDialogAssignment" :style="{ width: '98%' }"
          header="Visualizar asignación académica"
          :modal="true"
          class="p-fluid">
    <div class="card">
      <Toolbar class="border-0">
        <template v-slot:start>
          <Chip class="h-2.5rem mr-3">
            <i class="pi pi-user" style="font-size: 2rem"></i>
            <span class="ml-2 font-medium">Docente: <strong>{{ teacher.fullName }}</strong></span>
          </Chip>
        </template>
        <template v-slot:center>
          <Chip class="h-2.5rem mr-3">
            <i class="pi pi-clock" style="font-size: 2rem"></i>
            <span class="ml-2 font-medium">Carga Académica: <strong>{{ assignedHours }}</strong> horas</span>
          </Chip>
        </template>
        <template v-slot:end>
          <Button icon="pi pi-save" class="p-button-primary" label="Guardar"
                  @click="save"></Button>
        </template>
      </Toolbar>
    </div>
    <div class="card flex justify-center">
      <Toast/>
      <Tree ref="tree" v-model:selection-keys="selectedKey" filter-by="label,grade" :key="key" :filter="true"
            filterMode="lenient"
            :value="nodes"
            selectionMode="checkbox"
            class="w-full md:w-[30rem]" @nodeUnselect="onNodeUnselect" @nodeSelect="onNodeSelect">
        <template #default="{ node }">
          <div class="flex flex-col md:flex-row" @click.stop>
            <div v-if="node.data" class="mr-2" style="margin-top: 0.6rem;">
              <span>Curso: <strong>{{ node.label }}</strong></span>
            </div>
            <div v-else class="ml-3">
              <span class="">{{ node.label }}</span>
            </div>
            <div v-if="node.data" class="flex flex-col md:flex-row">
              <div class="mr-2 ml-2" style="margin-top: 0.6rem"><span>Intensidad Horaria</span></div>
              <div>
                <InputNumber v-model="node.data.value"
                             :min="1"
                             :max="maxHours"
                             :max-fraction-digits="0"
                             :step="1"
                             style="width: 6rem"
                             :disabled="evaluateCheck(node)"
                             @focusout="updateAssignedHours"
                             @change="updateAssignedHours"
                             @keydown="updateAssignedHours"
                             :suffix=" node.data.value ===1 ? ' hora':' horas'"
                >
                </InputNumber>
              </div>
              <div v-if="node.data.value > 1" class="flex flex-col md:flex-row">
                <div class="mr-2 ml-2" style="margin-top: 0.6rem"><span>Definir bloques de horas:</span></div>
                <div class="flex items-center">
                  <Select style="width: 15rem"
                          v-model="node.numBlock"
                          inputId="blocks"
                          :options="createBlocks(node.data.value)"
                          name="blocks"
                          checkmark
                          showClear
                          placeholder="Seleccione una opción"
                          @change="assignValue(node)"
                  >
                    <template #value="slotProps">
                      <div v-if="slotProps.value" class="flex items-center">
                        <div>{{ slotProps.value + (node.value === 1 ? ' Bloque' : ' Bloques')}}</div>
                      </div>
                    </template>
                    <template #option="slotProps">
                      <div class="flex items-center">
                        <div>{{ slotProps.option + (slotProps.option === 1 ? ' Bloque' : ' Bloques')}}</div>
                      </div>
                    </template>
                  </Select>
                  <div v-if="node.numBlock>0" class="mr-2 ml-2" style="margin-top: 0.6rem"><span>Distribución de Bloques:</span></div>
                  <div v-if="node.numBlock>0" class="flex flex-wrap gap-4 items-center">
                    <Select style="width: 16rem" v-model="node.blocks"
                            checkmark
                            placeholder="Seleccione una opción" inputId="blocks" :options="getBlocks(node)"
                            name="numBlocks"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Tree>
    </div>
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialogAssignment"/>
    </template>
  </Dialog>
  <Dialog v-model:visible="uploading" :modal="true" :blockScroll="true" :closable="false" :dismissableMask="false">
    <div class="full-screen-spinner">
      <ProgressSpinner></ProgressSpinner>
    </div>
  </Dialog>
</template>
<script>


import {API} from "@/utils/modules";
import {FilterMatchMode} from "@primevue/core/api";
import apiService from "@/service/api.service";
import {generateCombinations, sortByAttribute, sortByName} from "@/utils/funtions";

export default {
  data() {
    return {
      maxHours: 9,
      maxDays: 5,
      maximo: [1, 2, 3, 4, 5],
      selectedKey: [],
      key: 0,
      removed: [],
      courses: [],
      subjects: [],
      nodes: [],
      assignments: [],
      teacher: {},
      assignmentViewDialog: false,
      uploading: false,
      assignmentDialog: false,
      teachers: [],
      target: 22,
      selected: {},
      filters: {},
      assignedHours: 0,
      totalSubjects: 0
    };
  },
  methods: {
    assignValue(node){
      if(this.getBlocks(node).length === 1){
        node.blocks = this.getBlocks(node)[0];
      }
    },
    createBlocks(value) {
      let blocks = [];
      if (value > this.maxDays) {
        for (let i = 1; i <= this.maxDays; i++) {
          blocks.push(i);
        }
      } else {
        for (let i = 1; i <= value; i++) {
          blocks.push(i);
        }
      }
      return blocks;
    },
    findSubjectHours(nodeKey) {
      const subjectId = nodeKey.split('-')[0];
      return this.subjects.find(subject => subject.id === subjectId)?.hours || 0;
    },
    getBlocks(node) {
      const combinations = generateCombinations(node.data.value, this.maxDays);
      return combinations.filter(combination => combination.length === node.numBlock)
    },
    setChildNodeValues(children, value) {
      children.forEach(child => {
        if (child.data && (child.data.value === 0 || child.data.value === undefined || child.data.value === null)) {
          child.data.value = value;
        }
      });
    },
    onNodeSelect(node) {
      const subjectHours = this.findSubjectHours(node.key);
      if (node.children) {
        this.setChildNodeValues(node.children, subjectHours);
      } else if (node.data) {
        node.data.value = subjectHours;
      }
      this.updateAssignedHours();
    },
    async buildTreeStructure(data) {
      const CHECKED = true;
      const PARTIAL_CHECKED = true;
      const PARTIAL_UNCHECKED = false;
      const treeStructure = {};
      const initializeNode = (key, checked, partialChecked) => ({
        checked,
        partialChecked,
      });
      data.forEach(item => {
        const subjectId = item.subject.id;
        const courseId = item.course.id;
        const subjectCourseKey = `${subjectId}-${courseId}`;
        treeStructure[subjectId] = initializeNode(subjectId, CHECKED, PARTIAL_CHECKED);
        treeStructure[subjectCourseKey] = initializeNode(subjectCourseKey, CHECKED, PARTIAL_UNCHECKED);
        this.updateNodeValue(subjectCourseKey, item.hours, item.blocks);
      });
      Object.keys(treeStructure).forEach(subjectId => {
        if (!subjectId.includes('-')) {
          treeStructure[subjectId].checked = !CHECKED;
          treeStructure[subjectId].partialChecked = PARTIAL_CHECKED;
        }
      });
      this.assignedHours = data.reduce((acc, item) => acc + item.hours, 0);
      return treeStructure;
    },
    updateNodeValue(key, value, blocks) {
      const traverse = (nodes) => {
        for (const node of nodes) {
          if (node.key === key) {
            node.data.value = value;
            node.blocks = blocks;
            node.numBlock = blocks.length;
            return;
          }
          if (node.children) {
            traverse(node.children);
          }
        }
      };
      traverse(this.nodes);
    },
    onNodeUnselect() {
      this.updateAssignedHours();
    },
    getSelectedNodesWithParents() {
      const selectedNodesWithParents = [];
      const traverseNodes = (nodes, parent = null) => {
        nodes.forEach((node) => {
          if (this.selectedKey[node.key]) {
            if (parent) {
              selectedNodesWithParents.push({
                parent: parent.label,
                node: node
              });
            }
          }
          if (node.children) {
            traverseNodes(node.children, node);
          }
        });
      };
      traverseNodes(this.nodes);
      return selectedNodesWithParents;
    },
    getSelectedNodesDetails() {
      const selectedNodes = this.getSelectedNodesWithParents();
      return selectedNodes.map(node => {
        if (node.node.data) {
          const [subjectId, courseId] = node.node.key.split('-');
          return {
            subjectId: subjectId,
            courseId: courseId,
            value: node.node.data.value,
            blocks: node.node.blocks,
          };
        }
        return null;
      }).filter(detail => detail !== null);
    },
    evaluateCheck(node) {
      if (!this.selectedKey[node.key]) {
        node.data.value = null;
      }
      return !this.selectedKey[node.key];
    },
    updateAssignedHours() {
      this.assignedHours = this.getSelectedNodesWithParents()
          .filter(node => node.node.data && node.node.data.value)
          .reduce((accumulator, currentNode) => accumulator + currentNode.node.data.value, 0);
    },
    async getData() {
      try {
        const response = await apiService.getData(API, 'teachers/assignment');
        this.teachers = response.data;
        this.teachers = sortByAttribute(response.data, 'fullName');
        this.teachers = this.teachers.map(item => ({
          ...item,
          assignedMeter: item.assignedHours ? [{
            value: item.assignedHours,
            label: item.assignedHours + " -> "
          }] : [{value: 0}]
        }));
      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
      }
    },
    async getCourses() {
      try {
        const response = await apiService.getData(API, 'courses');
        this.courses = sortByAttribute(response.data, 'name')
      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
      }
    },
    async getSubjects() {
      try {
        const response = await apiService.getData(API, 'subjects');
        this.subjects = sortByName(response.data)
      } catch (error) {
        this.$toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
      }
    },
    async getAssignment(flag) {
      try {
        const response = await apiService.getData(API, 'assignments/email?email=' + this.teacher.email);
        this.assignments = response.data;
      } catch (error) {
        this.assignments = {};
        if (flag) {
          this.$toast.add({severity: 'warn', summary: 'Información', detail: error.response.data, life: 10000});
        }
      }
    },
    async save() {
      this.uploading = true;
      const selectedNodes = this.getSelectedNodesDetails();
      const request = {
        teacher: this.teacher.id,
        assignments: selectedNodes
      };
      console.log(request);
      return apiService.create(API, 'assignments', request).then(
          () => {
            this.uploading = false;
            this.$toast.add({severity: 'success', summary: 'Completado', detail: 'Registros Guardados!', life: 3000});
            this.assignmentDialog = false;
            this.getData();
          },
          (error) => {
            this.uploading = false;
            this.$toast.add({severity: 'error', summary: 'Error', detail: error.response.data, life: 10000});
          }
      );
    },
    hideDialog() {
      this.assignmentViewDialog = false;
      this.assignments = [];
    },
    hideDialogAssignment() {
      this.assignmentDialog = false;
      this.selectedKey = [];
      this.assignedHours = 0;
    },
    async assign(data) {
      this.teacher = data;
      await this.getAssignment(false);
      const keys = this.assignments;
      if (keys.length > 0) {
        this.selectedKey = await this.buildTreeStructure(keys);
      }
      this.assignmentDialog = true;
    },
    async view(data) {
      this.teacher = data;
      await this.getAssignment(true);
      if (this.assignments.length > 0) {
        this.assignedHours = this.assignments.reduce((acc, item) => acc + item.hours, 0);
        this.totalSubjects = this.assignments.length;
        this.assignmentViewDialog = true;
      }
    },
    buildNodes(subjects, courses) {
      const courseMap = new Map(courses.map(course => [course.id, course.name]));
      console.log(JSON.stringify(subjects)+'Algo')
      this.nodes =
          subjects.map(subject => ({
            label: subject.name,
            id: subject.id,
            key: subject.id,
            children: courses
                .filter(course => courseMap.has(course.id))
                .map(course => ({
                  label: course.name,
                  id: course.id,
                  grade: course.grade.name,
                  key: `${subject.id}-${course.id}`,
                  data: {value: subject.hours !== null && subject.hours !== undefined ? subject.hours : 0},
                  blocks: subject.blocks
                }))
          }));
    },
  },
  computed: {
    optionLabel(node) {
      return `node.numBlock ${node.numBlock === 1 ? 'bloque' : 'bloques'}`;
    }
  },
  async mounted() {
    await this.getData();
    await this.getCourses();
    await this.getSubjects();
    this.buildNodes(this.subjects, this.courses);
  },
  created() {
    this.filters = {
      global: {value: null, matchMode: FilterMatchMode.CONTAINS}
    };
  }
};
</script>
<style scoped>

::v-deep(.p-inputnumber-input) {
  width: 3rem !important;
  text-align: center;
}
</style>