<template>
  <div>
    <GenericCrud :formType="'StepperForm'" title="Estudiantes" path="students" property-name="firstName"
                 :columns-table="columns()" :form-fields="steps" :dropdown-options="dropdownOptions()"
                 :module="API()"/>
  </div>
</template>
<script>

import {Students} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";
import apiService from "@/service/api.service";
import {getData, sortByAttribute, sortByName} from "@/utils/funtions";

export default {
  components: {
    GenericCrud,
  },
  methods: {
    API() {
      return API
    },
    columns() {
      return Students
    },
    get(endpoint) {
      apiService.getData(API, endpoint)
          .then(response => {
            this[endpoint] = response.data;
          });
    },
    dropdownOptions() {
      return {
        genders: sortByAttribute(this.genders, 'domainDescription'),
        identificationTypes: sortByName(this.identificationTypes),
        cities: this.cities,
        departments: sortByAttribute(this.departments, 'name')
      };
    },
  },
  data() {
    return {
      genders : [],
      identificationTypes : [],
      departments : [],
      cities : [],
      steps: [
        { value: 1, label: 'Información básica', fields: [
            {id: 'firstName', label: 'Primer Nombre', model: 'firstName', type: 'text', required: true, props: {autofocus: true}},
            {id: 'secondName', label: 'Segundo Nombre', model: 'secondName', type: 'text', required: false, props: {autofocus: true}},
            {id: 'lastName', label: 'Primer Apellido', model: 'lastName', type: 'text', required: true, props: {autofocus: true}},
            {id: 'secondLastName', label: 'Segundo Apellido', model: 'secondLastName', type: 'text', required: false, props: {autofocus: true}},
            {id: 'identificationType', label: 'Tipo de identificación', model: 'identificationType', options:"identificationTypes", type: 'dropdown', required: true,
              props: { autofocus: true, placeholder: 'Seleccione un tipo de identificación', showClear: true, filter: true, optionLabel: 'name', optionValue: 'id'}},
            {id: 'identification', label: 'Número de identificación', model: 'identification', type: 'text', required: true, props: {autofocus: true}},
            {id: 'birthDate', label: 'Fecha de nacimiento', model: 'birthDate', type: 'date', required: true, props: {autofocus: true, showIcon: true, maxDate: new Date() }},
             {id: 'birthDepartments', label: 'Departamento de nacimiento', model: 'birthDepartments', options:'departments', type: 'dropdown', required: false, child:'birthPlace',
               keyValue: "codeDane",props: {autofocus: true, placeholder: 'Seleccione un departamento', showClear: true, filter: true, optionLabel: 'name'}},
             {id: 'birthPlace', label: 'Ciudad de nacimiento', model: 'birthPlace', options:'cities', type: 'dropdown', required: false, parent:'birthDepartments',
               keyValue: "parentCodeDane", keyParentValue: "codeDane", props: {autofocus: true, placeholder: 'Seleccione una ciudad', showClear: true, filter: true, optionLabel: 'name'}},
            {id: 'gender', label: 'Genero', model: 'gender', options:"genders", type: 'dropdown', required: false,
              props: {autofocus: true, placeholder: 'Seleccione un genero', showClear: true, filter: false, optionLabel: 'domainDescription', optionValue: 'domainValue'}}
          ] },
        { value: 2, label: 'Información de contacto', fields: [
            {id: 'phone', label: 'Teléfono', model: 'phone', type: 'text', required: true, props: {autofocus: true}},
            {id: 'email', label: 'Correo electrónico', model: 'email', type: 'text', required: true, props: {autofocus: true}},
            {id: 'address', label: 'Dirección', model: 'address', type: 'text', required: true, props: {autofocus: true}},
          ] }
      ]
    };
  },
  mounted() {
    getData('genders', 'domains/domain?domain=gender', this);
    getData('identificationTypes', 'identification-types', this);
    getData('departments', 'locations?locationType=D', this);
    getData('cities', 'locations?locationType=M', this)
  }
};
</script>
