<template>
  <Chip class="h-2.5rem mr-3">
    <i :class="icon" style="font-size: 2rem"></i>
    <span class="ml-2 font-medium" v-html="text"></span>
  </Chip>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: [String, Function],
      required: true
    }
  },
  computed: {
    formattedText() {
      return typeof this.text === 'function' ? this.text() : this.text;
    }
  }
}
</script>
