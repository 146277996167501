<template>
  <div>
    <GenericCrud
        :formType="'GenericForm'"
        title="Grados"
        path="grades"
        property-name="name"
        :columns-table="columns()"
        :form-fields="fields"
        :module="API()"
        :dropdown-options="dropdownOptions()"
    />
  </div>
</template>

<script>
import {Grades} from "@/entities/entities";
import GenericCrud from "@/components/GenericCrud.vue";
import {API} from "@/utils/modules";
import apiService from "@/service/api.service";

export default {
  components: {
    GenericCrud,
  },
  data() {
    return {
      levels: [],
      fields: [
        {id: 'name', label: 'Nombre', model: 'name', type: 'text', required: true, props: {autofocus: true}},
        {
          id: 'id', label: 'Nivel', options: 'levels', model: 'academicLevel', type: 'dropdown', required: true,
          props: {
            placeholder: 'Seleccione un nivel',
            showClear: true,
            filter: true,
            optionLabel: 'name'
          }
        },
        {
          id: 'minStudents', label: 'Número Mínimo de Estudiantes', model: 'minStudents', type: 'number', props: {autofocus: true, min: 0}, required: true
        },
        {
          id: 'prefix', label: 'Prefijo de Cursos', model: 'prefix', type: 'number', required: true
        }
      ]
    };
  },
  methods: {
    API() {
      return API;
    },
    get(endpoint) {
      apiService.getData(API, endpoint)
          .then(response => {
            this[endpoint] = response.data;
          });
    },
    columns() {
      return Grades;
    },
    dropdownOptions() {
      return{
        levels: this.levels
      }
    }
  },
  mounted() {
    this.get('levels');
  }
};
</script>
