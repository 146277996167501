import interceptor from "@/service/interceptor";
import {SECURITY} from "@/utils/modules";

const BASE_URL = process.env.VUE_APP_API_URL;

class ApiService {

    checkEmail(email) {
        return interceptor.get(BASE_URL + SECURITY + 'check-email?email=' + email)
    }
    getData(module, name) {
        return interceptor.get(BASE_URL + module + name)
    }
    getDataPost(module, name, record) {
        return interceptor.post(BASE_URL + module + name, record)
    }
    delete(module, name, record) {
        return interceptor.post(BASE_URL + module + name + '/delete', record)
    }

    deleteList(module, name, record) {
        return interceptor.post(BASE_URL + module + name + '/delete-list', record)
    }

    create(module, name, record) {
        return interceptor.post(BASE_URL + module + name, record)
    }

    update(module, name, record) {
        return interceptor.post(BASE_URL + module + name + '/update', record)
    }

    print(module, name, record) {
        return interceptor.post(BASE_URL + module + name, record, {responseType: 'blob'});
    }

}

export default new ApiService();
