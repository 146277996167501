<template>
  <div class="grid">

    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 text-2xl font-medium mb-3">Estudiantes</span>
            <div class="text-900 font-medium text-xl">{{ numStudents }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
               style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-users text-blue-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">2 -+ </span>
        <span class="text-500">since last visit</span>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 text-2xl font-medium mb-3">Cursos</span>
            <div class="text-900 font-medium text-xl">23</div>
          </div>
          <div class="flex align-items-center justify-content-center border-round"
               style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-tags text-orange-500 text-5xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">%52+ </span>
        <span class="text-500">since last week</span>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Customers</span>
            <div class="text-900 font-medium text-xl">28441</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
               style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-inbox text-cyan-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">520 </span>
        <span class="text-500">newly registered</span>
      </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
      <div class="card mb-0">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Comments</span>
            <div class="text-900 font-medium text-xl">152 Unread</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
               style="width: 2.5rem; height: 2.5rem">
            <i class="pi pi-comment text-purple-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">85 </span>
        <span class="text-500">responded</span>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <h5>Rendimiento Académico</h5>
        <Chart type="line" :data="lineData" :options="lineOptions"/>
      </div>
    </div>


    <div class="col-12 xl:col-6">
      <div class="card flex justify-center">
        <Chart type="radar" :data="chartData" :options="chartOptions" class="w-full md:w-[30rem]"/>
      </div>
      <div class="card">
          <Chart type="polarArea" :data="chartDataPolar" :options="chartOptionsPolar" class="w-full md:w-[30rem]" />
      </div>
    </div>
    <div class="col-12 xl:col-6">
      <div class="card">
        <div class="flex align-items-center justify-content-between mb-4">
          <h5>Notifications</h5>
          <div>
            <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded"
                    @click="$refs.menu1.toggle($event)"></Button>
            <Menu ref="menu1" :popup="true" :model="items"></Menu>
          </div>
        </div>

        <span class="block text-600 font-medium mb-3">TODAY</span>
        <ul class="p-0 mx-0 mt-0 mb-4 list-none">
          <li class="flex align-items-center py-2 border-bottom-1 surface-border">
            <div
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
              <i class="pi pi-dollar text-xl text-blue-500"></i>
            </div>
            <span class="text-900 line-height-3"
            >Richard Jones
                            <span class="text-700">has purchased a blue t-shirt for <span
                                class="text-blue-500">79$</span></span>
                        </span>
          </li>
          <li class="flex align-items-center py-2">
            <div
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
              <i class="pi pi-download text-xl text-orange-500"></i>
            </div>
            <span class="text-700 line-height-3">Your request for withdrawal of <span class="text-blue-500 font-medium">2500$</span> has been initiated.</span>
          </li>
        </ul>

        <span class="block text-600 font-medium mb-3">YESTERDAY</span>
        <ul class="p-0 m-0 list-none">
          <li class="flex align-items-center py-2 border-bottom-1 surface-border">
            <div
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
              <i class="pi pi-dollar text-xl text-blue-500"></i>
            </div>
            <span class="text-900 line-height-3"
            >Keyser Wick
                            <span class="text-700">has purchased a black jacket for <span
                                class="text-blue-500">59$</span></span>
                        </span>
          </li>
          <li class="flex align-items-center py-2 border-bottom-1 surface-border">
            <div
                class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
              <i class="pi pi-question text-xl text-pink-500"></i>
            </div>
            <span class="text-900 line-height-3"
            >Jane Davis
                            <span class="text-700">has posted a new questions about your product.</span>
                        </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chartData: null,
      chartDataPolar: null,
      chartOptions: null,
      chartOptionsPolar: null,
      productsTotal: null,
      numStudents: 0,
      products: [],
      lineData: {
        labels: ['Primer periodo', 'Segundo periodo', 'Tercer periodo'],
        datasets: [
          {
            label: '6-1',
            yAxisID: 'y1',
            data: [3.2, 4.1, 4.0, 5],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: 0.4,
            hidden: true
          },
          {
            label: '6-2',
            yAxisID: 'y1',
            data: [2.5, 3.2, 4.6, 3.9],
            fill: false,
            backgroundColor: '#ff6384',
            borderColor: '#ff6384',
            tension: 0.4,
            hidden: true
          },
          {
            label: '6-3',
            data: [4.0, 2.9, 3.5, 4.8],
            fill: false,
            backgroundColor: '#36a2eb',
            borderColor: '#36a2eb',
            tension: 0.4,
            hidden: true
          },
          {
            label: '7-1',
            data: [3.8, 2.6, 4.7, 3.3],
            fill: false,
            backgroundColor: '#cc65fe',
            borderColor: '#cc65fe',
            tension: 0.4,
            hidden: true
          },
          {
            label: '7-2',
            data: [2.4, 4.1, 3.9, 2.8],
            fill: false,
            backgroundColor: '#ffce56',
            borderColor: '#ffce56',
            tension: 0.4,
            hidden: true
          },
          {
            label: '8-1',
            data: [1.7, 3.5, 2.8, 4.6],
            fill: false,
            backgroundColor: '#e7e9ed',
            borderColor: '#e7e9ed',
            tension: 0.4,
            hidden: true
          },
          {
            label: '8-2',
            data: [4.0, 2.3, 3.6, 4.1],
            fill: false,
            backgroundColor: '#ff9f40',
            borderColor: '#ff9f40',
            tension: 0.4,
            hidden: true
          },
          {
            label: '9-1',
            data: [3.3, 4.4, 2.7, 3.9],
            fill: false,
            backgroundColor: '#4bc0c0',
            borderColor: '#4bc0c0',
            tension: 0.4,
            hidden: true
          },
          {
            label: '9-2',
            data: [4.2, 3.0, 2.9, 4.5],
            fill: false,
            backgroundColor: '#9966ff',
            borderColor: '#9966ff',
            tension: 0.4,
            hidden: true
          },
          {
            label: '10-1',
            data: [1.8, 4.6, 3.4, 2.5],
            fill: false,
            backgroundColor: '#ff6384',
            borderColor: '#ff6384',
            tension: 0.4,
            hidden: true
          },
          {
            label: '10-2',
            data: [3.0, 2.7, 4.1, 3.9],
            fill: false,
            backgroundColor: '#36a2eb',
            borderColor: '#36a2eb',
            tension: 0.4,
            hidden: true
          },
          {
            label: '11-1',
            data: [4.5, 2.8, 3.6, 4.3],
            fill: false,
            backgroundColor: '#cc65fe',
            borderColor: '#cc65fe',
            tension: 0.4,
            hidden: true
          },
          {
            label: '11-2',
            data: [2.3, 3.8, 4.4, 3.1],
            fill: false,
            backgroundColor: '#ffce56',
            borderColor: '#ffce56',
            tension: 0.4,
            hidden: true
          }
        ]
      },
      items: [
        {label: 'Add New', icon: 'pi pi-fw pi-plus'},
        {label: 'Remove', icon: 'pi pi-fw pi-minus'}
      ],
      lineOptions: null
    };
  },
  methods: {
    applyLightTheme() {
      this.lineOptions = {
        // indexAxis: 'y',
        aspectRatio: 3,
        plugins: {
          legend: {
            labels: {
              color: '#495057'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y: {
            ticks: {
              color: '#495057'
            },
            grid: {
              color: '#ebedef'
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: {
              color: '#ebedef'
            },
            grid: {
              drawOnChartArea: false,
              color: '#ebedef'
            }
          }
        }
      };
    },
    formatCurrency(value) {
      return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    },
    setChartData() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--p-text-color');

      return {
        labels: [
          'Diseño',
          'Ética y Religión',
          'Educación Física',
          'Artistica',
          'Quimica',
          'Fisica',
          'Ciencias Politicas',
          'Filosofia',
          'Informatica',
          'Español',
          'Inglés',
          'Matematicas',
          'Emprenderismo',
        ],
        datasets: [
          {
            label: 'Primer Periodo',
            borderColor: documentStyle.getPropertyValue('--p-gray-400'),
            pointBackgroundColor: documentStyle.getPropertyValue('--p-gray-400'),
            pointBorderColor: documentStyle.getPropertyValue('--p-gray-400'),
            pointHoverBackgroundColor: textColor,
            pointHoverBorderColor: documentStyle.getPropertyValue('--p-gray-400'),
            data: [
              4.5,
              2,
              4.3,
              4.6,
              3.4,
              4.5,
              2,
              2.9,
              2.7,
              2.8,
              1.5,
              3.8,
              4.3],
          },
          {
            label: 'Segundo Periodo',
            borderColor: documentStyle.getPropertyValue('--p-cyan-500'),
            pointBackgroundColor: documentStyle.getPropertyValue('--p-cyan-500'),
            pointBorderColor: documentStyle.getPropertyValue('--p-cyan-500'),
            pointHoverBackgroundColor: textColor,
            pointHoverBorderColor: documentStyle.getPropertyValue('--p-cyan-500'),
            data: [
              3.5,
              4.2,
              3.3,
              4.6,
              4.3,
              2,
              5,
              2.9,
              3.7,
              4.2,
              3.0,
              3.8,
              4.3],
          },

        ]
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--p-text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');

      return {
        plugins: {
          legend: {
            labels: {
              color: textColor
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: textColorSecondary
            }
          }
        }
      };
    },
    setChartDataPolar() {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        datasets: [
          {
            data: [
              2.6,
              2,
              4.3,
              4.6,
              3.4,
              4.5,
              2,
              2.9,
              2.7,
              2.8,
              1.5,
              3.8,
              4.3],

            backgroundColor: [
              documentStyle.getPropertyValue('--p-pink-500'),
              documentStyle.getPropertyValue('--p-gray-500'),
              documentStyle.getPropertyValue('--p-orange-500'),
              documentStyle.getPropertyValue('--p-purple-500'),
              documentStyle.getPropertyValue('--p-cyan-500')
            ],
            label: 'My dataset'
          }
        ],
        labels: [
          'Diseño',
          'Ética y Religión',
          'Educación Física',
          'Artistica',
          'Quimica',
          'Fisica',
          'Ciencias Politicas',
          'Filosofia',
          'Informatica',
          'Español',
          'Inglés',
          'Matematicas',
          'Emprenderismo'
        ],
      };
    },
    setChartOptionsPolar() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--p-text-color');
      const surfaceBorder = documentStyle.getPropertyValue('--p-content-border-color');

      return {
        plugins: {
          legend: {
            labels: {
              color: textColor
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: surfaceBorder
            }
          }
        }
      };
    }
  },
  mounted() {
    this.chartData = this.setChartData();
    this.chartOptions = this.setChartOptions();
    this.applyLightTheme();
    this.chartDataPolar = this.setChartDataPolar();
    this.chartOptionsPolar = this.setChartOptionsPolar();
  }
}
;
</script>
