import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store';
import AppLayout from "@/components/layout/AppLayout.vue";
import login from "@/views/pages/auth/Login.vue";
import access from "@/views/pages/auth/Access.vue";
import error from "@/views/pages/auth/Error.vue";
import home from "@/views/Dashboard.vue";
import Users from "@/views/security/UsersView.vue";
import GuardiansView from "@/views/GuardiansView.vue";
import StudentsView from "@/views/StudentsView.vue";
import ShiftsView from "@/views/ShiftsView.vue";
import GradesView from "@/views/GradesView.vue";
import CampusesView from "@/views/CampusesView.vue";
import YearsView from "@/views/YearsView.vue";
import PeriodsView from "@/views/PeriodsView.vue";
import CoursesView from "@/views/CoursesView.vue";
import SubjectsView from "@/views/SubjectsView.vue";
import ComponentsView from "@/views/ComponentsView.vue";
import TeachersView from "@/views/TeachersView.vue";
import StudentCoursesView from "@/views/StudentCoursesView.vue";
import AssignmentView from "@/views/AssignmentView.vue";
import AcademicHistoriesView from "@/views/AcademicHistoriesView.vue";
import ObservationCategoriesView from "@/views/ObservationCategoriesView.vue";
import ConceptDescriptionView from "@/views/ConceptDescriptionView.vue";
import PerformanceView from "@/views/PerformanceView.vue";
import ParametersView from "@/views/ParametersView.vue";
import ScheduleDataView from "@/views/ScheduleDataView.vue";
import DaysView from "@/views/scheduleBasic/DaysView.vue";
import HourDays from "@/views/scheduleBasic/HourDays.vue";
import ScheduleView from "@/views/scheduleBasic/ScheduleView.vue";
import UnavailabilityScheduleView from "@/views/scheduleBasic/UnavailabilityScheduleView.vue";
import GenerateCourses from "@/views/GenerateCourses.vue";
import AcademicReports from "@/views/reports/AcademicReports.vue";
import InstitutionInfoView from "@/views/InstitutionInfoView.vue";
import AcademicPerformanceView from "@/views/dashboard/AcademicPerformanceView.vue";

function createAuthRoute(path, component) {
    return {
        path: '/' + path,
        component: AppLayout,
        children: [
            {
                path: '/' + path,
                name: path,
                component: component,
                meta: {requiresAuth: true}
            },
        ]
    };
}

const routes = [
    { path: '/',component: AppLayout,children: [{path: '/',name: 'home',component: home,meta: {requiresAuth: true}}]},
    createAuthRoute('users', Users),
    createAuthRoute('guardians', GuardiansView),
    createAuthRoute('students', StudentsView),
    createAuthRoute('shifts', ShiftsView),
    createAuthRoute('grades', GradesView),
    createAuthRoute('campuses', CampusesView),
    createAuthRoute('years', YearsView),
    createAuthRoute('courses', CoursesView),
    createAuthRoute('teachers', TeachersView),
    createAuthRoute('subjects', SubjectsView),
    createAuthRoute('periods', PeriodsView),
    createAuthRoute('components', ComponentsView),
    createAuthRoute('assignments', AssignmentView),
    createAuthRoute('student-courses', StudentCoursesView),
    createAuthRoute('attendances', GradesView),
    createAuthRoute('observations', GradesView),
    createAuthRoute('observation-categories', ObservationCategoriesView),
    createAuthRoute('student-guardians', GradesView),
    createAuthRoute('institution', InstitutionInfoView),
    createAuthRoute('academic-histories', AcademicHistoriesView),
    createAuthRoute('concept-descriptions', ConceptDescriptionView),
    createAuthRoute('performances', PerformanceView),
    createAuthRoute('parameters', ParametersView),
    createAuthRoute('/schedule/basic', ScheduleDataView),
    createAuthRoute('/schedule/basic/days', DaysView),
    createAuthRoute('/schedule/basic/hours', HourDays),
    createAuthRoute('/schedule/basic/schedule', ScheduleView),
    createAuthRoute('/schedule/basic/unavailabilityScheduleView', UnavailabilityScheduleView),
    createAuthRoute('/generate', GenerateCourses),
    createAuthRoute('/dashboard/academic-performance', AcademicPerformanceView),
    createAuthRoute('/reports/AcademicReports', AcademicReports),
    {path: '/auth/login', name: 'login', component: login},
    {path: '/auth/access', name: 'accessDenied', component: access},
    {path: '/auth/error', name: 'error', component: error},
];

const router = createRouter({history: createWebHistory(), routes});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        store.dispatch('validateToken')
        if (store.getters.IsLoggedIn) {
            next();
        } else {
            next({name: 'login'});
        }
    } else {
        next();
    }
});

export default router;
